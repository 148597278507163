var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"onramp-navigation"},[_c('span',{staticClass:"current-step"},[_vm._v(_vm._s(_vm.currentStepLabel))]),_c('div',{staticClass:"onramp-navigation-inner"},[[_c('router-link',{staticClass:"complete",attrs:{"to":{ name: 'service' }}},[_c('span',[_vm._v("Services")])]),_c('router-link',{class:{ complete: _vm.isCompleted('vehicle') },attrs:{"disabled":!_vm.isCompleted('vehicle'),"event":_vm.isCompleted('vehicle') ? 'click' : '',"to":{ name: 'vehicle' }}},[_c('span',[_vm._v("Vehicle")])])],_c('router-link',{class:{ complete: _vm.isCompleted('estimate') },attrs:{"disabled":!_vm.isCompleted('estimate'),"event":_vm.isCompleted('estimate') ? 'click' : '',"to":{
        name: 'offer-selection',
        params: {
          id: this.$route.params.id || 0,
          offerId: this.$route.params.offerId || 0
        }
      }}},[_c('span',[_vm._v("Estimates")])]),_c('router-link',{class:{ complete: _vm.isCompleted('book') },attrs:{"disabled":!_vm.isCompleted('book'),"event":_vm.isCompleted('book') ? 'click' : '',"to":{
        name: 'schedule',
        params: {
          id: this.$route.params.id || 0,
          offerId: this.$route.params.offerId || 0
        }
      }}},[_c('span',[_vm._v("Book")])]),_c('router-link',{attrs:{"disabled":!_vm.isCompleted('confirm'),"event":_vm.isCompleted('confirm') ? 'click' : '',"to":{
        name: 'subscription-confirm-offer',
        params: {
          id: this.$route.params.id || 0,
          offerId: this.$route.params.offerId || 0
        }
      }}},[_c('span',[_vm._v("Confirm")])])],2),_c('transition',{attrs:{"name":"fade-out","mode":"out-in"}},[_c('span',{key:new Date().getTime(),staticClass:"sidebar-toggler",on:{"click":function($event){return _vm.toggleSidebar()}}},[_c('icon',{attrs:{"icon":['fal', ("chevron-" + _vm.chevronDirection)]}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }