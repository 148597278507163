import { Route } from 'vue-router';

import userService from '@/services/userService';
import store from '@/store/store';
import { component } from 'vue/types/umd';

const Onramp = () => import(/* webpackChunkName: "onramp" */ '@/views/Onramp.vue');
const ServiceRequestCreation = () =>
  import(/* webpackChunkName: "onramp" */ '@/components/Onramp/ServiceRequestCreation.vue');
const ServiceSelector = () =>
  import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Services/ServiceSelector.vue');
const VehicleSelector = () =>
  import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Vehicle/VehicleSelector.vue');
const ContactSelector = () => import(/* webpackChunkName: "onramp" */ '@/components/Onramp/ContactSelector.vue');
const OfferSelector = () => import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Offer/OfferSelector.vue');
const AppointmentSelector = () =>
  import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Appointment/AppointmentSelector.vue');
const ConfirmStep = () => import(/* webpackChunkName: "onramp" */ '@/components/Entries/ConfirmStep.vue');
const SubscriptionConfirmStep = () =>
  import(/* webpackChunkName: "onramp" */ '@/components/Entries/SubscriptionConfirmStep.vue');
const Confirmation = () => import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Confirmation.vue');
const Completed = () => import(/* webpackChunkName: "onramp" */ '@/components/Onramp/Completed.vue');
const SignUp = () => import(/* webpackChunkName: "onramp" */ '@/components/Onramp/SignUp.vue');
const NpsSurvey = () => import(/* webpackChunkName: "onramp" */ '@/components/ServiceRequests/NPS/NpsSurvey.vue');
const ServiceAdvisor = () =>
  import(/* webpackChunkName: "service-advisor" */ '@/components/Onramp/ServiceAdvisor/ServiceAdvisor.vue');

export default [
  {
    path: '/service-request',
    name: 'service-request',
    component: Onramp,
    meta: {
      transitionName: 'slide-left',
      public: true,
      onramp: true
    },
    children: [
      // On Ramp routes
      {
        path: 'services',
        name: 'services',
        component: ServiceRequestCreation,
        redirect: { name: 'service' },
        meta: {
          public: true
        },
        children: [
          {
            path: 'service',
            name: 'service',
            component: ServiceSelector,
            meta: {
              step: 'services',
              title: window.innerWidth > 768 ? 'What services do you need?' : 'Choose Services.',
              showCloseButtonInNav: true
            },
            beforeEnter: (_to: Route, from: Route, next: any) => {
              if (from.name === 'offer-selection') {
                store.commit('onrampCart/resetServices');
              }
              next();
            }
          },
          {
            path: 'service-advisor',
            name: 'service-advisor',
            component: ServiceAdvisor,
            meta: {
              step: 'services',
              title: 'Conversation Started',
              showCloseButtonInNav: true
            },
            beforeEnter: (_to: Route, from: Route, next: any) => {
              if (from.name === 'offer-selection') {
                store.commit('onrampCart/resetServices');
              }
              next();
            }
          },
          {
            path: 'location',
            name: 'location',
            component: ServiceSelector,
            meta: {
              step: 'location',
              title: window.innerWidth > 768 ? 'Where do you need service?' : 'Choose Location',
              showCloseButtonInNav: true
            }
          },
          {
            path: 'interview',
            name: 'interview',
            component: ServiceSelector,
            meta: {
              step: 'services',
              title: 'Answer some questions.',
              showCloseButtonInNav: true
            }
          },
          {
            path: 'notes/:type',
            name: 'notes',
            component: ServiceSelector,
            meta: {
              step: 'services',
              title: 'Add a note for the mechanic.',
              showCloseButtonInNav: true
            }
          },
          {
            path: 'interval-services',
            name: 'interval-services',
            component: ServiceSelector,
            meta: {
              step: 'services',
              title: 'Recommended services for your vehicle?',
              showCloseButtonInNav: true
            }
          }
        ]
      },
      {
        name: 'account',
        path: 'account',
        component: ServiceRequestCreation,
        redirect: { name: 'sign-up' },
        meta: {
          public: true
        },
        children: [
          {
            path: 'sign-up',
            name: 'sign-up',
            component: SignUp,
            meta: {
              step: 'sign-up',
              title: 'Create your free Openbay Account.',
              showCloseButtonInNav: true
            }
          },
          {
            path: 'activate-account',
            name: 'onramp-activate-account',
            component: SignUp,
            meta: {
              step: 'sign-up',
              title: 'Confirm your account.',
              showCloseButtonInNav: true
            }
          }
        ]
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: ServiceRequestCreation,
        redirect: { name: 'vehicle' },
        meta: {
          public: true
        },
        children: [
          {
            path: 'vehicle',
            name: 'vehicle',
            component: VehicleSelector,
            meta: {
              step: 'vehicle',
              transitionName: 'slide-left',
              title: 'Tell us about your vehicle',
              showCloseButtonInNav: true
            },
            children: [
              {
                path: 'add',
                name: 'add-vehicle-onramp',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/make',
                name: 'add-vehicle-onramp-make',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/model',
                name: 'add-vehicle-onramp-model',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/trim',
                name: 'add-vehicle-onramp-trim',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/subModel',
                name: 'add-vehicle-onramp-subModel',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/mileage',
                name: 'add-vehicle-onramp-mileage',
                component: VehicleSelector,
                //@ts-ignore
                beforeEnter: (_to, from, next) => {
                  if (from.name === 'interval-services') {
                    next('/service-request/vehicle');
                  } else {
                    next();
                  }
                },
                meta: {
                  step: 'vehicle',
                  title: 'How many miles are on your vehicle?',
                  transitionName: 'slide-left',
                  showCloseButtonInNav: true
                }
              }
            ]
          }
        ]
      },
      {
        name: 'notify',
        path: 'notify',
        component: ServiceRequestCreation,
        meta: {
          step: 'contact',
          title: 'How can we reach you when estimates are ready?',
          showCloseButtonInNav: true,
          public: true
        },
        children: [
          {
            name: 'contact',
            path: 'contact',
            component: ContactSelector,
            meta: {
              step: 'contact',
              title: 'How can we reach you when estimates are ready?',
              showCloseButtonInNav: true
            }
          }
        ]
      },
      {
        path: 'nps/:nps_token',
        component: NpsSurvey,
        beforeEnter: (_to: Route, from: Route, next: any): void => {
          userService
            .fetchServiceRequestByNpsToken(_to.params.nps_token)
            .then((sr) => {
              if (sr.settled) {
                store.commit('onrampCart/setServiceRequest', sr);
                next();
              } else {
                next('/404');
              }
            })
            .catch(() => {
              next('/404');
            });
        },
        meta: {
          public: true,
          step: 'nps',
          hideSidebar: true
        }
      },
      {
        path: ':id/offer',
        name: 'offer-selection',
        component: OfferSelector,
        props: true,
        meta: {
          title: 'Compare estimates and select a shop.',
          step: 'offer',
          showCloseButtonInNav: true,
          public: false
        }
      },
      {
        path: ':id/offer/:offerId',
        name: 'offer-quick-view',
        component: OfferSelector,
        props: true,
        meta: {
          title: 'Compare estimates and select a shop.',
          step: 'offer',
          showCloseButtonInNav: true,
          public: false
        }
      },
      {
        path: ':id/offer/:offerId/book',
        name: 'schedule',
        component: AppointmentSelector,
        props: true,
        meta: {
          title: 'Choose an appointment time',
          step: 'schedule',
          showCloseButtonInNav: true,
          public: false
        }
      },
      {
        path: ':id/offer/:offerId/confirm',
        name: 'confirm-offer',
        component: ConfirmStep,
        props: true,
        meta: {
          step: 'confirm',
          public: false
        },
        children: [
          {
            path: 'register',
            name: 'confirm-register',
            meta: {
              title: 'Create an Openbay Account',
              step: 'confirm',
              showCloseButtonInNav: true
            }
          },
          {
            path: 'payment',
            name: 'confirm-payment',
            meta: {
              title: 'Enter payment info to confirm booking.',
              step: 'confirm',
              showCloseButtonInNav: true
            }
          }
        ]
      },
      {
        path: ':id/offer/:offerId/subscription-confirm',
        name: 'subscription-confirm-offer',
        component: SubscriptionConfirmStep,
        props: true,
        meta: {
          title: 'Confirm service details to book your appointment.',
          step: 'confirm',
          showCloseButtonInNav: true,
          hideSidebar: true,
          public: false
        }
      },
      {
        path: ':id/offer/:offerId/confirmation',
        name: 'confirmation',
        component: Confirmation,
        props: true,
        meta: {
          title: '',
          step: 'confirmation',
          public: false
        }
      }
    ]
  },
  {
    path: '/service-request/:id/offer/:offerId/completed',
    name: 'service-request-completed',
    component: Completed,
    props: true,
    meta: {
      title: 'Service Completed',
      public: false,
    }
  }
];
