import { AxiosRequestConfig } from 'axios';
import { Moment } from 'moment';

export interface Pagination {
  skip: number;
  take: number;
  total: number;
}

export enum SortDirection {
  ascending = 'ascending',
  descending = 'descending'
}

export interface Sort<T> {
  prop: T;
  order: SortDirection;
}

export interface ApiListResponse<T> {
  data: T[];
  pagination: Pagination;
}

export interface RetriableAxiosRequestConfig extends AxiosRequestConfig {
  retried?: boolean;
}

export interface RoadsideActivationRequest {
  subscription_id: number | null;
  owned_vehicle_id?: number;
  start?: Moment;
  end?: Moment;
  action?: RoadsideActions;
  master_id?: string;
}

export type NSDErrorResponse = {
  data: {
    action: string;
    test: boolean;
    status: {
      status_code: string;
      status_message: string;
    };
  };
};

export type NSDSuccessResponse = {
  data: {
    action: string;
    test: boolean;
    status: {
      status_code: string;
      status_message: string;
    };
    document: {
      member_no: string;
      master_id: string;
      pdf_base64: string;
    };
  };
};

export type NSDResponse = NSDSuccessResponse | NSDErrorResponse;

export type NSDRequest = {
  email: string;
  owned_vehicle_id: number;
};

export enum RoadsideActions {
  BIND = 'bind',
  UPDATE = 'update',
  REPRINT = 'reprint'
}

export function isRoadsideSuccessResponse(res: NSDResponse): res is NSDSuccessResponse {
  return 'document' in res.data;
}
