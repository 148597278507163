
























































































import { BModal } from 'bootstrap-vue';
import emailInput from '@/components/Input/EmailInput.vue';
import nameInput from '@/components/Input/RequiredInput.vue';
import userService from '../../services/userService';
import segmentServices from '@/services/segmentServices';
import { Alert } from '@/store/types';
import { BetaContact, BetaContactProduct } from '@/types/resources/BetaContact';
import { Subscription } from '@/types/microservices/Subscriptions';
import AddShopForm from '@/components/Shared/AddShopForm.vue';
import { AreaWithRelativeDistance } from '@/types/resources/ZipcodeLocations';

import CombinedComponent, { configMixin, userMixin } from '@/mixins';
import TurboService from '@/services/microservices/turboService';

export default CombinedComponent(userMixin, configMixin).extend({
  name: 'ZeroCoverage',

  components: {
    BModal,
    emailInput,
    nameInput,
    AddShopForm
  },

  props: {
    outOfMarketZipcode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      loading: false,
      addingShop: false,
      canSubmit: false,
      success: false,
      emailValid: false,
      nearbyAreas: [] as AreaWithRelativeDistance[]
    };
  },

  computed: {
    inMarket(): boolean {
      return !this.outOfMarketZipcode;
    },

    isOpenbayPlusUser(): boolean {
      return this.$store.getters['user/getSubscriptions'].length > 0;
    },

    activeSubscription(): Subscription | undefined {
      return this.$store.getters['user/getSubscriptions'][0];
    },

    activeSubscriptionProgramPlanId(): number | null {
      return this.activeSubscription?.programPlanId ?? null;
    },

    openbayBranding(): string {
      return this.isOpenbayPlusUser ? `${this.labels.openbayPlus}` : `${this.labels.openbay}`;
    },

    addShopForm(): InstanceType<typeof AddShopForm> {
      return this.$refs['addShopForm'] as InstanceType<typeof AddShopForm>;
    },

    modalTitle(): string {
      return this.addingShop ? 'Add a Shop' : '';
    },

    cancelText(): string {
      return this.addingShop ? 'Back' : 'Cancel';
    },

    topNearestAreas(): AreaWithRelativeDistance[] {
      return this.nearbyAreas.slice(0, 6);
    },

    modalSubheading(): string {
      return this.nearbyAreas.length > 0
        ? `${this.openbayBranding} isn't available in your location yet. Try a nearby zipcode where we have shops in network.`
        : `${this.openbayBranding} isn't available in your location yet. Share your info to get notified when nearby shops join ${this.openbayBranding}.`;
    }
  },

  watch: {
    inMarket(newVal: boolean, _oldVal: boolean): void {
      if (!newVal) this.launchModal();
    },
    outOfMarketZipcode(newVal): void {
      if (!newVal) return;

      new TurboService().fetchNearbyAreas(newVal).then((response) => {
        this.nearbyAreas = response;
      });
    }
  },

  mounted(): void {
    if (!this.inMarket) this.launchModal();
  },

  methods: {
    launchModal(): void {
      this.addingShop = false;
      segmentServices.trackGeneral(this.isOpenbayPlusUser ? 'No Openbay+ Modal Viewed' : 'No Coverage Modal Viewed');

      (this.$refs['zeroCoverage'] as BModal).show();
    },

    hideModal(): void {
      if (this.$refs['zeroCoverage']) (this.$refs['zeroCoverage'] as BModal).hide();
    },

    leaveOnramp(): void {
      const route = this.isLoggedIn ? '/dashboard' : '/';
      this.$store.commit('onrampCart/setZipcode', '');
      this.$store.commit('user/setZipcode', '');
      if (this.$route.path !== route) this.$router.push(route);
    },

    updateEmail(newEmail: string): void {
      this.email = newEmail;
    },

    updateEmailValidity(isValid: boolean): void {
      this.emailValid = isValid;
    },

    cancel(): void {
      if (this.addingShop) {
        this.addingShop = false;
      } else {
        this.hideModal();
      }
    },

    addShop(): void {
      this.addingShop = true;
      this.success = false;
      this.canSubmit = false;
    },

    submitShopForm(): void {
      this.addShopForm.submit().then(() => {
        this.success = true;
      });
    },

    updateFirstName(name: string): void {
      this.firstName = name;
    },

    updateLastName(name: string): void {
      this.lastName = name;
    },

    sendToBetaContacts(): void {
      this.loading = true;

      let product: BetaContactProduct = 'Openbay OTM';
      let programPlanId = null;
      if (this.isOpenbayPlusUser) {
        product = 'Openbay Plus';
        programPlanId = this.activeSubscriptionProgramPlanId;
      }

      const betaContact: BetaContact = {
        zipcode: this.outOfMarketZipcode,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        product,
        programPlanId
      };

      userService
        .updateBetaContacts(betaContact)
        .then((_response) => {
          segmentServices.trackGeneral(
            this.isOpenbayPlusUser ? 'No Openbay+ Modal Submitted' : 'No Coverage Modal Submitted'
          );

          const alertMessage = `We will notify you at ${this.email} when nearby shops join ${this.openbayBranding}.`;
          this.showAlert(alertMessage, 'success');
        })
        .catch((error) => {
          const alertMessage =
            error === 'Email has already been taken'
              ? `You’ve already requested ${this.openbayBranding} service coverage notifications.`
              : 'Hmm, something went wrong. Sorry about that.';

          this.showAlert(alertMessage, 'warning');
        })
        .finally(() => {
          this.loading = false;
          this.hideModal();
        });
    },

    showAlert(message: string, type: Alert['type']): void {
      const alert: Alert = { message, type, timedDismiss: true };
      this.$store.dispatch('alerts/addAlert', alert);
    },

    chooseNearbyArea(area: AreaWithRelativeDistance) {
      this.$router.push({ name: 'service', query: { zipcode: area.zipcode } });
      this.hideModal();
    }
  }
});
