export default [
  {
    name: 'auto-repair-index',
    path: '/auto-repair',
    component: () => import(/* webpackChunkName: "auto-repair" */ '@/views/AutoRepair.vue'),
    meta: {
      public: true,
      whiteBackground: true,
      fluidContainer: true,
      hideMainSlot: true,
      disableTracking: true
    }
  },
  {
    name: 'auto-repair-cities',
    path: '/auto-repair/:stateAbbreviation',
    component: () => import(/* webpackChunkName: "auto-repair" */ '@/views/AutoRepair.vue'),
    meta: {
      public: true,
      whiteBackground: true,
      fluidContainer: true,
      hideMainSlot: true,
      disableTracking: true
    }
  },
  {
    name: 'auto-repair-service-locations',
    path: '/auto-repair/:stateAbbreviation/:city',
    component: () => import(/* webpackChunkName: "auto-repair" */ '@/views/AutoRepair.vue'),
    meta: {
      whiteBackground: true,
      public: true,
      fluidContainer: true,
      hideMainSlot: true,
      disableTracking: true,
      appointment: true
    }
  }
];
