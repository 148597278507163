













































































































import { BTab, BTabs } from 'bootstrap-vue';
import { uniq } from 'underscore';

import { gtagService } from '@/services/gtagService';
import { FormattedSubscriptionOption } from '@/types/FormattedSubscriptionOption';
import { PerksList, SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { monetize } from '@/utilities';

import SubscriptionOptionsComparison from './SubscriptionOptionsComparison.vue';
import SubscriptionOptionsFaq from './SubscriptionOptionsFaq.vue';

const FREE_PLAN: FormattedSubscriptionOption = {
  label: 'Free',
  subHeading: 'Current Plan',
  amount: 0,
  description:
    'Find, get upfront pricing, and book automotive service. Discounts on services are shown, but not applied to booked services.',
  borderClass: 'border-red',
  formattedPrice: '0'
};

import CombinedComponent, { configMixin, userMixin } from '@/mixins';
import { Frequency } from '@/types/microservices/Frequency';

const annualPlans = ['yearly', 'fifteenMo'].map((f) => f.toLowerCase());
const monthlyPlans = ['monthly', 'semiAnnual', 'quarterly'].map((f) => f.toLowerCase());

const perksList: PerksList[] = [
  // 0
  { title: 'Unlimited requests for service' },
  { title: 'Upfront service pricing' },
  { title: 'Multiple competitive price estimates' },
  { title: 'Automotive services: repair, maintenance, car wash, auto body, glass replacement' },
  { title: 'Online appointment booking' },
  { title: 'Service history' },

  // 6
  { title: 'All items in the Free plan' },
  { title: 'Discount on automotive services upwards of 25%' },
  { title: 'Concierge service' },
  { title: 'Savings of $400 - $2,100 annually' },

  // 10
  { title: 'All items listed in Openbay+' },
  {
    title: 'Roadside Assistance includes',
    children: [
      { title: 'Jump start, lockout, flat tire change, winching, fuel delivery, and towing up to 10-miles.' },
      { title: '3 events per year' },
      { title: 'Begins 3-days after signing' }
    ]
  }
];

export default CombinedComponent(userMixin, configMixin).extend({
  name: 'SubscriptionOptions',

  components: {
    BTabs,
    BTab,
    SubscriptionOptionsComparison,
    SubscriptionOptionsFaq
  },

  props: {
    modal: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    showFreePlanButton: {
      type: Boolean,
      default: false
    },
    hideFreePlan: {
      type: Boolean,
      default: false
    },
    fromPage: {
      type: String,
      required: false,
      default: 'banner'
    }
  },

  data() {
    return {
      tab: 0,
      loading: false,
      showComparison: true,
      showAnnualPlans: false
    };
  },

  computed: {
    faqRef(): InstanceType<typeof SubscriptionOptionsFaq> {
      return this.$refs['faqs'] as InstanceType<typeof SubscriptionOptionsFaq>;
    },

    optionColumnClasses(): string[] {
      // We might need to tweak these later
      let classes = ['option-col', 'col mt-2'];
      return classes;
    },

    partnerId(): number {
      const [program] = this.$store.getters['user/getPrograms'];
      return program.partnerId || 0;
    },

    subscriptionOptions(): FormattedSubscriptionOption[] {
      const showAnnualPlans = this.showAnnualPlans;
      const options = this.formatSubscriptionOptions(
        this.$store.getters['user/getSubscriptionOptions'].filter((so: SubscriptionOption) => {
          return showAnnualPlans
            ? annualPlans.includes(so.frequency.toLowerCase())
            : monthlyPlans.includes(so.frequency.toLowerCase());
        })
      );
      if (!this.hideFreePlan && options.length < 4) options.unshift(FREE_PLAN);
      return options.sort((opt1, opt2) => (opt1.amount || 0) - (opt2.amount || 0));
    }
  },

  async mounted() {
    if (!this.hideFreePlan && !this.subscriptionOptions.find((o) => o.amount === 0))
      this.subscriptionOptions.push(FREE_PLAN);
    gtagService.event('subscription_plans_viewed', {
      from: this.fromPage
    });
    window.addEventListener('resize', this.windowResized);
    this.showComparison = window.innerWidth >= 768 || !this.modal;
  },

  destroyed() {
    window.removeEventListener('resize', this.windowResized);
  },

  methods: {
    windowResized() {
      if (!this.modal) return;

      this.showComparison = window.innerWidth >= 768;
    },

    getFrequencyAbbreviation(frequency: string) {
      return (
        {
          Yearly: '12 mo',
          Monthly: '1 mo',
          Quarterly: '3 mo',
          SemiAnnual: '6 mo',
          fifteenMo: '15 mo'
        }[frequency] || ''
      );
    },

    frequencyToNoun(frequency: string) {
      return (
        {
          Yearly: 'year',
          Monthly: 'month',
          SemiAnnual: 'months',
          Quarterly: 'quarter'
        }[frequency] || ''
      );
    },

    formatSubscriptionOptions(options: SubscriptionOption[]): FormattedSubscriptionOption[] {
      return uniq(options, 'id').map(this.formatSubscriptionOption);
    },

    getSubheading(frequency: string) {
      return (
        {
          Free: 'Free Plan',
          Yearly: '12 Month Plan',
          Monthly: '1 Month Plan',
          SemiAnnual: '6 Month Plan',
          Quarterly: '3 Month Plan'
        }[frequency] || ''
      );
    },

    getFrequencyLabel(frequency: string) {
      return (
        {
          Yearly: '12 months',
          Monthly: '1 month',
          Quarterly: '3 months'
        }[frequency] || ''
      );
    },

    /* For quarterly plans we display how much it would be per month but billing is every 3 months  */
    priceForDisplay(option: FormattedSubscriptionOption) {
      if (option.frequency === 'Quarterly' && option.amount)
        return monetize(option.amount / 3, 'cents').replace('$', '');

      return option.formattedPrice;
    },

    frequencyForDisplay(option: FormattedSubscriptionOption) {
      if (
        option.frequency &&
        (monthlyPlans as Array<keyof typeof Frequency>).includes(
          option.frequency.toLowerCase() as unknown as keyof typeof Frequency
        ) &&
        option.amount
      )
        return '6 mo';

      return this.getFrequencyAbbreviation(option.frequency!);
    },
    /* ============================================================================================== */

    formatSubscriptionOption(option: SubscriptionOption, index: number = 0): FormattedSubscriptionOption {
      const borderColors = ['border-red', 'border-blue', 'border-orange'];
      const formatted = {
        borderClass: borderColors[(index + 1) % borderColors.length],
        subHeading: this.getSubheading(option.frequency),
        formattedPrice: monetize(option.amount, 'cents').replace('$', '')
      };
      return { ...formatted, ...option };
    },

    customBadgeClasses(option: FormattedSubscriptionOption, index: number): string[] {
      const classes = ['bg-success'];
      const previousOption: FormattedSubscriptionOption | undefined = this.subscriptionOptions[index - 1];
      if (option.frequency === 'Yearly' && previousOption?.frequency === 'Yearly') classes.push('badge-border-left');
      return classes;
    },

    captialize(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    showOpenbayRecommended(opt: FormattedSubscriptionOption): boolean {
      return !!opt.hasRoadside;
      // if (opt.hasRoadside) return true;
      // if (!opt.frequency) return false;
      // return ['Yearly', 'fifteenMo'].includes(opt.frequency);
    },

    filteredPerks(so: FormattedSubscriptionOption): PerksList[] {
      if ((so.frequency as string) == 'Yearly' || (so.frequency as string) == 'semiAnnual') {
        if (so.hasRoadside) return perksList.slice(-2);
        return perksList.slice(6, 10);
      }

      return perksList.slice(0, 5);
    }
  }
});
