export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/components/Profile/Profile.vue'),
    meta: {
      inProfile: true,
      transitionName: 'slide'
    },
    children: [
      {
        path: 'account',
        name: 'profile-account',
        meta: {
          inProfile: true,
          subPage: true
        }
      },
      {
        path: 'password',
        name: 'profile-password',
        meta: {
          inProfile: true,
          subPage: true
        }
      },
      {
        path: 'vehicle',
        name: 'profile-vehicle',
        meta: {
          inProfile: true,
          subPage: true
        }
      },
      {
        path: 'reviews',
        name: 'profile-reviews',
        meta: {
          inProfile: true,
          subPage: true
        }
      },
      {
        path: 'subscriptions',
        name: 'profile-subscriptions',
        meta: {
          inProfile: true,
          subPage: true
        }
      },
      {
        path: 'roadside',
        name: 'profile-roadside',
        meta: {
          inProfile: true,
          subPage: true
        }
      }
    ]
  }
];
