import { httpClientMicro } from '@/api-microservices';
import { Config, fetchConfig } from '@/init/app-config';
import { Area, AreaWithRelativeDistance, CityAreas, TargetMarket } from '@/types/resources/ZipcodeLocations';

export default class TurboService {
  config: Config;
  routes = {
    zipcodeSearch: () => `${this.environmentPath()}/turbo/v1/zipcode/search`,
    targetMarketCheck: () => `${this.environmentPath()}/turbo/v1/zipcode/target_market`,
    citiesInState: (state: string) => `${this.environmentPath()}/turbo/v1/states/${state}/cities`,
    nearbyActiveAreas: (zipcode: string) => `${this.environmentPath()}/turbo/v1/areas/${zipcode}/with_active_shops`,
    cityAreas: (state: string, city: string) => `${this.environmentPath()}/turbo/v1/states/${state}/cities/${city}`
  };
  constructor() {
    this.config = fetchConfig();
  }

  environmentPath() {
    return this.config.get('VUE_APP_TURBO_API_BASE_URL');
  }

  public getZipcodeSearch(term: string): Promise<Area[]> {
    return httpClientMicro.get(`${this.routes.zipcodeSearch()}?term=${term}`).then((resp) => resp.data);
  }

  public getTargetMarketCheck(zipcode: string): Promise<TargetMarket> {
    return httpClientMicro.get(`${this.routes.targetMarketCheck()}?zipcode=${zipcode}`).then((resp) => resp.data);
  }

  public getCitiesInState(state: string): Promise<string[]> {
    return httpClientMicro.get(this.routes.citiesInState(state)).then((resp) => resp.data.cities);
  }

  public fetchNearbyAreas(zipcode: string): Promise<AreaWithRelativeDistance[]> {
    return httpClientMicro.get(this.routes.nearbyActiveAreas(zipcode)).then((resp) => resp.data.areas);
  }

  public fetchCityAreas(state: string, city: string): Promise<CityAreas> {
    return httpClientMicro.get(this.routes.cityAreas(state, city)).then((resp) => resp.data);
  }
}
